export default {
  aquadata: {
    communicationToolsOnProjectInformationPageWhileAddingOrUpdating: {
      show: false,
      internalCommunication: {
        show: false
      },
      citizenOpinions: {
        show: false
      },
      externalCommunication: {
        show: false
      }
    },
    dropDownNoticeTypeInReportProjectModalOnProjectListiing: {
      show: false
    },
    dropDownClosuresInReportProjectModalOnProjectListiing: {
      show: false
    },
    exportProjectButtonOnProjectListingHeader: {
      show: false,
      exportCsv : {
        show: false
      },
      exportXls : {
        show : false
      },
      exportGeojson : {
        show : false
      }
    },
    exportProjectButtonInProjectListing: {
      show: false
    },
    projectReminderButtonInProjectListing: {
      show: false,
      remindByEmail: {
        show: false,
      },
      remindBySMS: {
        show: false,
      }
    },
    userTypeAdminGISRadioButtonInUserEdit: {
      show: false
    },
    publishProjectToPublicMap: {
      show: true,
      publishToCitizen: {
        show: true,
      },
      publishProjectPhase: {
        show: true,
      },
      publishProjectWorkNotice: {
        show : true,
      },
      publishProjectToWaze: {
        show: true
      }
    },
    ssoLoginEsri:{
      show: true
    },
    ssoLoginMicrosoft:{
      show: false
    },
    emailLogsOnSidebar:{
      show: true
    },
    feedbackOnSidebar: {
      show: true
    }
  },
  bell: {
    communicationToolsOnProjectInformationPageWhileAddingOrUpdating: {
      show: false,
      internalCommunication: {
        show: false
      },
      citizenOpinions: {
        show: false
      },
      externalCommunication: {
        show: false
      }
    },
    dropDownNoticeTypeInReportProjectModalOnProjectListiing: {
      show: false
    },
    dropDownClosuresInReportProjectModalOnProjectListiing: {
      show: false
    },
    exportProjectButtonOnProjectListingHeader: {
      show: false,
      exportCsv : {
        show: false
      },
      exportXls : {
        show : false
      },
      exportGeojson : {
        show : false
      }
    },
    exportProjectButtonInProjectListing: {
      show: false
    },
    projectReminderButtonInProjectListing: {
      show: false,
      remindByEmail: {
        show: false,
      },
      remindBySMS: {
        show: false,
      }
    },
    userTypeAdminGISRadioButtonInUserEdit: {
      show: false
    },
    publishProjectToPublicMap: {
      show: true,
      publishToCitizen: {
        show: true,
      },
      publishProjectPhase: {
        show: true,
      },
      publishProjectWorkNotice: {
        show : true,
      },
      publishProjectToWaze: {
        show: true
      }
    },
    ssoLoginEsri:{
      show: true
    },
    ssoLoginMicrosoft:{
      show: false
    },
    emailLogsOnSidebar:{
      show: true
    },
    feedbackOnSidebar: {
      show: false
    }
  },
  boisbriand: {
    communicationToolsOnProjectInformationPageWhileAddingOrUpdating: {
      show: false,
      internalCommunication: {
        show: false
      },
      citizenOpinions: {
        show: false
      },
      externalCommunication: {
        show: false
      }
    },
    dropDownNoticeTypeInReportProjectModalOnProjectListiing: {
      show: false
    },
    dropDownClosuresInReportProjectModalOnProjectListiing: {
      show: false
    },
    exportProjectButtonOnProjectListingHeader: {
      show: false,
      exportCsv : {
        show: false
      },
      exportXls : {
        show : false
      },
      exportGeojson : {
        show : false
      }
    },
    exportProjectButtonInProjectListing: {
      show: false
    },
    projectReminderButtonInProjectListing: {
      show: false,
      remindByEmail: {
        show: false,
      },
      remindBySMS: {
        show: false,
      }
    },
    userTypeAdminGISRadioButtonInUserEdit: {
      show: false
    },
    publishProjectToPublicMap: {
      show: true,
      publishToCitizen: {
        show: true,
      },
      publishProjectPhase: {
        show: true,
      },
      publishProjectWorkNotice: {
        show : true,
      },
      publishProjectToWaze: {
        show: true
      }
    },
    ssoLoginEsri:{
      show: true
    },
    ssoLoginMicrosoft:{
      show: true
    },
    emailLogsOnSidebar:{
      show: true
    },
    feedbackOnSidebar: {
      show: false
    }
  },
  brossard: {
    communicationToolsOnProjectInformationPageWhileAddingOrUpdating: {
      show: false,
      internalCommunication: {
        show: false
      },
      citizenOpinions: {
        show: false
      },
      externalCommunication: {
        show: false
      }
    },
    dropDownNoticeTypeInReportProjectModalOnProjectListiing: {
      show: false
    },
    dropDownClosuresInReportProjectModalOnProjectListiing: {
      show: false
    },
    exportProjectButtonOnProjectListingHeader: {
      show: false,
      exportCsv : {
        show: false
      },
      exportXls : {
        show : false
      },
      exportGeojson : {
        show : false
      }
    },
    exportProjectButtonInProjectListing: {
      show: false
    },
    projectReminderButtonInProjectListing: {
      show: true,
      remindByEmail: {
        show: true,
      },
      remindBySMS: {
        show: false,
      }
    },
    userTypeAdminGISRadioButtonInUserEdit: {
      show: false
    },
    publishProjectToPublicMap: {
      show: true,
      publishToCitizen: {
        show: true,
        defaultValue: true,
      },
      publishProjectPhase: {
        show: true,
        defaultValue: true,
      },
      publishProjectWorkNotice: {
        show : true,
        defaultValue: true,
      },
      publishProjectToWaze: {
        show: true,
        defaultValue: true,
      }
    },
    ssoLoginEsri:{
      show: false
    },
    ssoLoginMicrosoft:{
      show: false
    },
    emailLogsOnSidebar:{
      show: true
    },
    feedbackOnSidebar: {
      show: true 
    },
    showGpsPositionButton:{
      show:true
    },
    searchSource:{
      isQuebec: false,
      isDefaultESRI: false,
      isDefaultCenterline: true,
    }
  },
  can: {
    communicationToolsOnProjectInformationPageWhileAddingOrUpdating: {
      show: false,
      internalCommunication: {
        show: false
      },
      citizenOpinions: {
        show: false
      },
      externalCommunication: {
        show: false
      }
    },
    dropDownNoticeTypeInReportProjectModalOnProjectListiing: {
      show: false
    },
    dropDownClosuresInReportProjectModalOnProjectListiing: {
      show: false
    },
    exportProjectButtonOnProjectListingHeader: {
      show: false,
      exportCsv : {
        show: false
      },
      exportXls : {
        show : false
      },
      exportGeojson : {
        show : false
      }
    },
    exportProjectButtonInProjectListing: {
      show: false
    },
    projectReminderButtonInProjectListing: {
      show: true,
      remindByEmail: {
        show: true,
      },
      remindBySMS: {
        show: false,
      }
    },
    userTypeAdminGISRadioButtonInUserEdit: {
      show: false
    },
    publishProjectToPublicMap: {
      show: true,
      publishToCitizen: {
        show: true,
      },
      publishProjectPhase: {
        show: true,
      },
      publishProjectWorkNotice: {
        show : true,
      },
      publishProjectToWaze: {
        show: true
      }
    },
    ssoLoginEsri:{
      show: true
    },
    ssoLoginMicrosoft:{
      show: false
    },
    emailLogsOnSidebar:{
      show: true
    },
    feedbackOnSidebar: {
      show: false
    }
  },
  canada: {
    communicationToolsOnProjectInformationPageWhileAddingOrUpdating: {
      show: false,
      internalCommunication: {
        show: false
      },
      citizenOpinions: {
        show: false
      },
      externalCommunication: {
        show: false
      }
    },
    dropDownNoticeTypeInReportProjectModalOnProjectListiing: {
      show: false
    },
    dropDownClosuresInReportProjectModalOnProjectListiing: {
      show: false
    },
    exportProjectButtonOnProjectListingHeader: {
      show: false,
      exportCsv : {
        show: false
      },
      exportXls : {
        show : false
      },
      exportGeojson : {
        show : false
      }
    },
    exportProjectButtonInProjectListing: {
      show: false
    },
    projectReminderButtonInProjectListing: {
      show: true,
      remindByEmail: {
        show: true,
      },
      remindBySMS: {
        show: false,
      }
    },
    userTypeAdminGISRadioButtonInUserEdit: {
      show: false
    },
    publishProjectToPublicMap: {
      show: true,
      publishToCitizen: {
        show: true,
      },
      publishProjectPhase: {
        show: true,
      },
      publishProjectWorkNotice: {
        show : true,
      },
      publishProjectToWaze: {
        show: true
      }
    },
    ssoLoginEsri:{
      show: true
    },
    ssoLoginMicrosoft:{
      show: false
    },
    emailLogsOnSidebar:{
      show: true
    },
    feedbackOnSidebar: {
      show: false
    }
  },
  gatineau: {
    communicationToolsOnProjectInformationPageWhileAddingOrUpdating: {
      show: true,
      internalCommunication: {
        show: true
      },
      citizenOpinions: {
        show: true
      },
      externalCommunication: {
        show: false
      }
    },
    dropDownNoticeTypeInReportProjectModalOnProjectListiing: {
      show: true
    },
    dropDownClosuresInReportProjectModalOnProjectListiing: {
      show: true
    },
    exportProjectButtonOnProjectListingHeader: {
      show: true,
      exportCsv : {
        show: true
      },
      exportXls : {
        show : true
      },
      exportGeojson : {
        show : false
      }
    },
    exportProjectButtonInProjectListing: {
      show: false
    },
    projectReminderButtonInProjectListing: {
      show: true,
      remindByEmail: {
        show: true,
      },
      remindBySMS: {
        show: false,
      }
    },
    userTypeAdminGISRadioButtonInUserEdit: {
      show: true
    },
    publishProjectToPublicMap: {
      show: true,
      defaultValue : true,
      publishToCitizen: {
        show: true,
        defaultValue: true,
      },
      publishProjectPhase: {
        show: true,
        defaultValue: true,
      },
      publishProjectWorkNotice: {
        show : true,
        defaultValue: true,
      },
      publishProjectToWaze: {
        show: true
      }
    },
    ssoLoginEsri:{
      show: false
    },
    ssoLoginMicrosoft:{
      show: false
    },
    emailLogsOnSidebar:{
      show: true
    },
    feedbackOnSidebar: {
      show: true
    },
    quickCreateProject:{
      show: true
    },
    stakeholderAutocomplete:{
      show:true
    },
    searchSource:{
      isQuebec: true,
      isDefaultESRI: false,
      isDefaultCenterline: false,
    },
    autoSave:{
      show:true
    },
    helpOnSidebar:{
      show: true
    }
  },
  gis: {
    communicationToolsOnProjectInformationPageWhileAddingOrUpdating: {
      show: false,
      internalCommunication: {
        show: false
      },
      citizenOpinions: {
        show: false
      },
      externalCommunication: {
        show: false
      }
    },
    dropDownNoticeTypeInReportProjectModalOnProjectListiing: {
      show: false
    },
    dropDownClosuresInReportProjectModalOnProjectListiing: {
      show: false
    },
    exportProjectButtonOnProjectListingHeader: {
      show: true,
      exportCsv : {
        show: true
      },
      exportXls : {
        show : true
      },
      exportGeojson : {
        show : true
      }
    },
    exportProjectButtonInProjectListing: {
      show: true
    },
    projectReminderButtonInProjectListing: {
      show: true,
      remindByEmail: {
        show: true,
      },
      remindBySMS: {
        show: true,
      }
    },
    userTypeAdminGISRadioButtonInUserEdit: {
      show: true
    },
    publishProjectToPublicMap: {
      show: true,
      publishToCitizen: {
        show: true,
      },
      publishProjectPhase: {
        show: true,
      },
      publishProjectWorkNotice: {
        show : true,
      },
      publishProjectToWaze: {
        show: true
      }
    },
    ssoLoginEsri:{
      show: true
    },
    ssoLoginMicrosoft:{
      show: false
    },
    emailLogsOnSidebar:{
      show: true
    },
    feedbackOnSidebar: {
      show: true
    },
    searchSource:{
      isQuebec: true,
      isDefaultESRI: true,
      isDefaultCenterline: true,
    }
  },
  hampstead: {
    communicationToolsOnProjectInformationPageWhileAddingOrUpdating: {
      show: false,
      internalCommunication: {
        show: false
      },
      citizenOpinions: {
        show: false
      },
      externalCommunication: {
        show: false
      }
    },
    dropDownNoticeTypeInReportProjectModalOnProjectListiing: {
      show: false
    },
    dropDownClosuresInReportProjectModalOnProjectListiing: {
      show: false
    },
    exportProjectButtonOnProjectListingHeader: {
      show: false,
      exportCsv : {
        show: false
      },
      exportXls : {
        show : false
      },
      exportGeojson : {
        show : false
      }
    },
    exportProjectButtonInProjectListing: {
      show: false
    },
    projectReminderButtonInProjectListing: {
      show: true,
      remindByEmail: {
        show: true,
      },
      remindBySMS: {
        show: false,
      }
    },
    userTypeAdminGISRadioButtonInUserEdit: {
      show: false
    },
    publishProjectToPublicMap: {
      show: true,
      publishToCitizen: {
        show: true,
      },
      publishProjectPhase: {
        show: true,
      },
      publishProjectWorkNotice: {
        show : true,
      },
      publishProjectToWaze: {
        show: true
      }
    },
    ssoLoginEsri:{
      show: true
    },
    ssoLoginMicrosoft:{
      show: false
    },
    emailLogsOnSidebar:{
      show: true
    },
    feedbackOnSidebar: {
      show: false
    },
    searchSource:{
      isQuebec: false,
      isDefaultESRI: false,
      isDefaultCenterline: true,
    }
  },
  montroyal: {
    communicationToolsOnProjectInformationPageWhileAddingOrUpdating: {
      show: true,
      internalCommunication: {
        show: true
      },
      citizenOpinions: {
        show: true
      },
      externalCommunication: {
        show: true
      }
    },
    dropDownNoticeTypeInReportProjectModalOnProjectListiing: {
      show: false
    },
    dropDownClosuresInReportProjectModalOnProjectListiing: {
      show: true
    },
    exportProjectButtonOnProjectListingHeader: {
      show: false,
      exportCsv : {
        show: false
      },
      exportXls : {
        show : false
      },
      exportGeojson : {
        show : false
      }
    },
    exportProjectButtonInProjectListing: {
      show: false
    },
    projectReminderButtonInProjectListing: {
      show: true,
      remindByEmail: {
        show: true,
      },
      remindBySMS: {
        show: false,
      }
    },
    userTypeAdminGISRadioButtonInUserEdit: {
      show: true
    },
    publishProjectToPublicMap: {
      show: true,
      publishToCitizen: {
        show: true,
      },
      publishProjectPhase: {
        show: true,
      },
      publishProjectWorkNotice: {
        show : true,
      },
      publishProjectToWaze: {
        show: true
      }
    },
    ssoLoginEsri:{
      show: false
    },
    ssoLoginMicrosoft:{
      show: false
    },
    emailLogsOnSidebar:{
      show: true
    },
    feedbackOnSidebar: {
      show: true
    },
    searchSource:{
      isQuebec: false,
      isDefaultESRI: false,
      isDefaultCenterline: true,
    }
  },
  quebec: {
    communicationToolsOnProjectInformationPageWhileAddingOrUpdating: {
      show: false,
      internalCommunication: {
        show: false
      },
      citizenOpinions: {
        show: false
      },
      externalCommunication: {
        show: false
      }
    },
    dropDownNoticeTypeInReportProjectModalOnProjectListiing: {
      show: false
    },
    dropDownClosuresInReportProjectModalOnProjectListiing: {
      show: false
    },
    exportProjectButtonOnProjectListingHeader: {
      show: false,
      exportCsv : {
        show: false
      },
      exportXls : {
        show : false
      },
      exportGeojson : {
        show : false
      },
      emailLogsOnSidebar:{
        show: true
      },
      feedbackOnSidebar: {
        show: false
      }
    },
    exportProjectButtonInProjectListing: {
      show: false
    },
    projectReminderButtonInProjectListing: {
      show: true,
      remindByEmail: {
        show: true,
      },
      remindBySMS: {
        show: false,
      }
    },
    userTypeAdminGISRadioButtonInUserEdit: {
      show: false
    },
    publishProjectToPublicMap: {
      show: true,
      publishToCitizen: {
        show: true,
      },
      publishProjectPhase: {
        show: true,
      },
      publishProjectWorkNotice: {
        show : true,
      },
      publishProjectToWaze: {
        show: true
      }
    },
    ssoLoginEsri:{
      show: true
    },
    ssoLoginMicrosoft:{
      show: false
    }
  },
  repentigny: {
    communicationToolsOnProjectInformationPageWhileAddingOrUpdating: {
      show: false,
      internalCommunication: {
        show: false
      },
      citizenOpinions: {
        show: false
      },
      externalCommunication: {
        show: false
      }
    },
    dropDownNoticeTypeInReportProjectModalOnProjectListiing: {
      show: false
    },
    dropDownClosuresInReportProjectModalOnProjectListiing: {
      show: false
    },
    exportProjectButtonOnProjectListingHeader: {
      show: false,
      exportCsv : {
        show: false
      },
      exportXls : {
        show : false
      },
      exportGeojson : {
        show : false
      }
    },
    exportProjectButtonInProjectListing: {
      show: false
    },
    projectReminderButtonInProjectListing: {
      show: true,
      remindByEmail: {
        show: true,
      },
      remindBySMS: {
        show: false,
      }
    },
    userTypeAdminGISRadioButtonInUserEdit: {
      show: false
    },
    publishProjectToPublicMap: {
      show: true,
      publishToCitizen: {
        show: true,
      },
      publishProjectPhase: {
        show: true,
      },
      publishProjectWorkNotice: {
        show : true,
      },
      publishProjectToWaze: {
        show: true
      }
    },
    ssoLoginEsri:{
      show: true
    },
    ssoLoginMicrosoft:{
      show: true
    },
    emailLogsOnSidebar:{
      show: true
    },
    feedbackOnSidebar: {
      show: false
    }
  },
  roadworks: {
    communicationToolsOnProjectInformationPageWhileAddingOrUpdating: {
      show: false,
      internalCommunication: {
        show: false
      },
      citizenOpinions: {
        show: false
      },
      externalCommunication: {
        show: false
      }
    },
    dropDownNoticeTypeInReportProjectModalOnProjectListiing: {
      show: false
    },
    dropDownClosuresInReportProjectModalOnProjectListiing: {
      show: false
    },
    exportProjectButtonOnProjectListingHeader: {
      show: true,
      exportCsv : {
        show: true
      },
      exportXls : {
        show : true
      },
      exportGeojson : {
        show : true
      }
    },
    exportProjectButtonInProjectListing: {
      show: true
    },
    projectReminderButtonInProjectListing: {
      show: true,
      remindByEmail: {
        show: true,
      },
      remindBySMS: {
        show: true,
      }
    },
    userTypeAdminGISRadioButtonInUserEdit: {
      show: true
    },
    publishProjectToPublicMap: {
      show: true,
      publishToCitizen: {
        show: true,
      },
      publishProjectPhase: {
        show: true,
      },
      publishProjectWorkNotice: {
        show : true,
      },
      publishProjectToWaze: {
        show: true
      }
    },
    ssoLoginEsri:{
      show: true
    },
    ssoLoginMicrosoft:{
      show: true
    },
    emailLogsOnSidebar:{
      show: true
    },
    feedbackOnSidebar: {
      show: true
    },
    searchSource:{
      isQuebec: false,
      isDefaultESRI: true,
      isDefaultCenterline: false,
    }
  },
  safelane: {
    communicationToolsOnProjectInformationPageWhileAddingOrUpdating: {
      show: false,
      internalCommunication: {
        show: false
      },
      citizenOpinions: {
        show: false
      },
      externalCommunication: {
        show: false
      }
    },
    dropDownNoticeTypeInReportProjectModalOnProjectListiing: {
      show: false
    },
    dropDownClosuresInReportProjectModalOnProjectListiing: {
      show: false
    },
    exportProjectButtonOnProjectListingHeader: {
      show: true,
      exportCsv : {
        show: false
      },
      exportXls : {
        show : false
      },
      exportGeojson : {
        show : false
      }
    },
    exportProjectButtonInProjectListing: {
      show: true
    },
    projectReminderButtonInProjectListing: {
      show: true,
      remindByEmail: {
        show: true,
      },
      remindBySMS: {
        show: true,
      }
    },
    userTypeAdminGISRadioButtonInUserEdit: {
      show: true
    },
    publishProjectToPublicMap: {
      show: true,
      publishToCitizen: {
        show: true,
      },
      publishProjectPhase: {
        show: true,
      },
      publishProjectWorkNotice: {
        show : true,
      },
      publishProjectToWaze: {
        show: true
      }
    },
    ssoLoginEsri:{
      show: true
    },
    ssoLoginMicrosoft:{
      show: false
    },
    emailLogsOnSidebar:{
      show: true
    },
    feedbackOnSidebar: {
      show: true
    }
  },
  sjsr: {
    communicationToolsOnProjectInformationPageWhileAddingOrUpdating: {
      show: false,
      internalCommunication: {
        show: false
      },
      citizenOpinions: {
        show: false
      },
      externalCommunication: {
        show: false
      }
    },
    dropDownNoticeTypeInReportProjectModalOnProjectListiing: {
      show: true
    },
    dropDownClosuresInReportProjectModalOnProjectListiing: {
      show: false
    },
    exportProjectButtonOnProjectListingHeader: {
      show: false,
      exportCsv : {
        show: false
      },
      exportXls : {
        show : false
      },
      exportGeojson : {
        show : false
      }
    },
    exportProjectButtonInProjectListing: {
      show: false
    },
    projectReminderButtonInProjectListing: {
      show: true,
      remindByEmail: {
        show: true,
      },
      remindBySMS: {
        show: false,
      }
    },
    userTypeAdminGISRadioButtonInUserEdit: {
      show: true
    },
    publishProjectToPublicMap: {
      show: true,
      publishToCitizen: {
        show: true,
      },
      publishProjectPhase: {
        show: true,
      },
      publishProjectWorkNotice: {
        show : true,
      },
      publishProjectToWaze: {
        show: true
      }
    },
    ssoLoginEsri:{
      show: true
    },
    ssoLoginMicrosoft:{
      show: false
    },
    emailLogsOnSidebar:{
      show: true
    },
    feedbackOnSidebar: {
      show: true
    },
    searchSource:{
      isQuebec: true,
      isDefaultESRI: false,
      isDefaultCenterline: false,
    }
  },
  toronto: {
    communicationToolsOnProjectInformationPageWhileAddingOrUpdating: {
      show: false,
      internalCommunication: {
        show: false
      },
      citizenOpinions: {
        show: false
      },
      externalCommunication: {
        show: false
      }
    },
    dropDownNoticeTypeInReportProjectModalOnProjectListiing: {
      show: false
    },
    dropDownClosuresInReportProjectModalOnProjectListiing: {
      show: false
    },
    exportProjectButtonOnProjectListingHeader: {
      show: false,
      exportCsv : {
        show: false
      },
      exportXls : {
        show : false
      },
      exportGeojson : {
        show : false
      }
    },
    exportProjectButtonInProjectListing: {
      show: false
    },
    projectReminderButtonInProjectListing: {
      show: false,
      remindByEmail: {
        show: false,
      },
      remindBySMS: {
        show: false,
      }
    },
    userTypeAdminGISRadioButtonInUserEdit: {
      show: false
    },
    publishProjectToPublicMap: {
      show: true,
      publishToCitizen: {
        show: true,
      },
      publishProjectPhase: {
        show: true,
      },
      publishProjectWorkNotice: {
        show : true,
      },
      publishProjectToWaze: {
        show: true
      }
    },
    ssoLoginEsri:{
      show: true
    },
    ssoLoginMicrosoft:{
      show: false
    },
    emailLogsOnSidebar:{
      show: true
    },
    feedbackOnSidebar: {
      show: false
    }
  },
  us: {
    communicationToolsOnProjectInformationPageWhileAddingOrUpdating: {
      show: false,
      internalCommunication: {
        show: false
      },
      citizenOpinions: {
        show: false
      },
      externalCommunication: {
        show: false
      }
    },
    dropDownNoticeTypeInReportProjectModalOnProjectListiing: {
      show: false
    },
    dropDownClosuresInReportProjectModalOnProjectListiing: {
      show: false
    },
    exportProjectButtonOnProjectListingHeader: {
      show: false,
      exportCsv : {
        show: false
      },
      exportXls : {
        show : false
      },
      exportGeojson : {
        show : false
      }
    },
    exportProjectButtonInProjectListing: {
      show: false
    },
    projectReminderButtonInProjectListing: {
      show: false,
      remindByEmail: {
        show: false,
      },
      remindBySMS: {
        show: false,
      }
    },
    userTypeAdminGISRadioButtonInUserEdit: {
      show: false
    },
    publishProjectToPublicMap: {
      show: true,
      publishToCitizen: {
        show: true,
      },
      publishProjectPhase: {
        show: true,
      },
      publishProjectWorkNotice: {
        show : true,
      },
      publishProjectToWaze: {
        show: true
      }
    },
    ssoLoginEsri:{
      show: true
    },
    ssoLoginMicrosoft:{
      show: false
    },
    emailLogsOnSidebar:{
      show: true
    },
    feedbackOnSidebar: {
      show: true
    }
  },
  vancouver: {
    communicationToolsOnProjectInformationPageWhileAddingOrUpdating: {
      show: false,
      internalCommunication: {
        show: false
      },
      citizenOpinions: {
        show: false
      },
      externalCommunication: {
        show: false
      }
    },
    dropDownNoticeTypeInReportProjectModalOnProjectListiing: {
      show: false
    },
    dropDownClosuresInReportProjectModalOnProjectListiing: {
      show: false
    },
    exportProjectButtonOnProjectListingHeader: {
      show: false,
      exportCsv : {
        show: false
      },
      exportXls : {
        show : false
      },
      exportGeojson : {
        show : false
      }
    },
    exportProjectButtonInProjectListing: {
      show: false
    },
    projectReminderButtonInProjectListing: {
      show: false,
      remindByEmail: {
        show: false,
      },
      remindBySMS: {
        show: false,
      }
    },
    userTypeAdminGISRadioButtonInUserEdit: {
      show: false
    },
    publishProjectToPublicMap: {
      show: true,
      publishToCitizen: {
        show: true,
      },
      publishProjectPhase: {
        show: true,
      },
      publishProjectWorkNotice: {
        show : true,
      },
      publishProjectToWaze: {
        show: true
      }
    },
    ssoLoginEsri:{
      show: true
    },
    ssoLoginMicrosoft:{
      show: false
    },
    emailLogsOnSidebar:{
      show: true
    },
    feedbackOnSidebar: {
      show: false
    }
  },
  cigo: {
    communicationToolsOnProjectInformationPageWhileAddingOrUpdating: {
      show: false,
      internalCommunication: {
        show: false
      },
      citizenOpinions: {
        show: false
      },
      externalCommunication: {
        show: false
      }
    },
    dropDownNoticeTypeInReportProjectModalOnProjectListiing: {
      show: false
    },
    dropDownClosuresInReportProjectModalOnProjectListiing: {
      show: false
    },
    exportProjectButtonOnProjectListingHeader: {
      show: true,
      exportCsv : {
        show: true
      },
      exportXls : {
        show : true
      },
      exportGeojson : {
        show : true
      }
    },
    exportProjectButtonInProjectListing: {
      show: true
    },
    projectReminderButtonInProjectListing: {
      show: true,
      remindByEmail: {
        show: true,
      },
      remindBySMS: {
        show: true,
      }
    },
    userTypeAdminGISRadioButtonInUserEdit: {
      show: true
    },
    publishProjectToPublicMap: {
      show: true,
      publishToCitizen: {
        show: true,
      },
      publishProjectPhase: {
        show: true,
      },
      publishProjectWorkNotice: {
        show : true,
      },
      publishProjectToWaze: {
        show: true
      }
    },
    ssoLoginEsri:{
      show: true
    },
    ssoLoginMicrosoft:{
      show: false
    },
    emailLogsOnSidebar:{
      show: true
    },
    feedbackOnSidebar: {
      show: true
    }
  },
  mississauga: {
    communicationToolsOnProjectInformationPageWhileAddingOrUpdating: {
      show: false,
      internalCommunication: {
        show: false
      },
      citizenOpinions: {
        show: false
      },
      externalCommunication: {
        show: false
      }
    },
    dropDownNoticeTypeInReportProjectModalOnProjectListiing: {
      show: true
    },
    dropDownClosuresInReportProjectModalOnProjectListiing: {
      show: false
    },
    exportProjectButtonOnProjectListingHeader: {
      show: true,
      exportCsv : {
        show: true
      },
      exportXls : {
        show : true
      },
      exportGeojson : {
        show : true
      }
    },
    exportProjectButtonInProjectListing: {
      show: true
    },
    projectReminderButtonInProjectListing: {
      show: true,
      remindByEmail: {
        show: true,
      },
      remindBySMS: {
        show: true,
      }
    },
    userTypeAdminGISRadioButtonInUserEdit: {
      show: true
    },
    publishProjectToPublicMap: {
      show: true,
      publishToCitizen: {
        show: true,
      },
      publishProjectPhase: {
        show: true,
      },
      publishProjectWorkNotice: {
        show : true,
      },
      publishProjectToWaze: {
        show: true
      }
    },
    ssoLoginEsri:{
      show: true
    },
    ssoLoginMicrosoft:{
      show: true
    },
    emailLogsOnSidebar:{
      show: true
    },
    feedbackOnSidebar: {
      show: true
    },
    quickCreateProject:{
      show: true
    },
    searchSource:{
      isQuebec: false,
      isDefaultESRI: true,
      isDefaultCenterline: false,
    },
    isCustomSidebar:{
      conflictPage: true,
      publicMapPage: true,
      timelinePage: true,
      userManagement: true,
    },
    isCustomIcon:{
      timeline: true,
      project: true,
    }
  },
  admtl: {
    communicationToolsOnProjectInformationPageWhileAddingOrUpdating: {
      show: false,
      internalCommunication: {
        show: false
      },
      citizenOpinions: {
        show: false
      },
      externalCommunication: {
        show: false
      }
    },
    dropDownNoticeTypeInReportProjectModalOnProjectListiing: {
      show: false
    },
    dropDownClosuresInReportProjectModalOnProjectListiing: {
      show: false
    },
    exportProjectButtonOnProjectListingHeader: {
      show: false,
      exportCsv : {
        show: false
      },
      exportXls : {
        show : false
      },
      exportGeojson : {
        show : false
      }
    },
    exportProjectButtonInProjectListing: {
      show: false
    },
    projectReminderButtonInProjectListing: {
      show: true,
      remindByEmail: {
        show: true,
      },
      remindBySMS: {
        show: false,
      }
    },
    userTypeAdminGISRadioButtonInUserEdit: {
      show: true
    },
    publishProjectToPublicMap: {
      show: true,
      publishToCitizen: {
        show: true,
      },
      publishProjectPhase: {
        show: true,
      },
      publishProjectWorkNotice: {
        show : true,
      },
      publishProjectToWaze: {
        show: true
      }
    },
    ssoLoginEsri:{
      show: true
    },
    ssoLoginMicrosoft:{
      show: false
    },
    emailLogsOnSidebar:{
      show: true
    },
    feedbackOnSidebar: {
      show: false
    },
    searchSource:{
      isQuebec: true,
      isDefaultESRI: false,
      isDefaultCenterline: false,
    }
  },
}